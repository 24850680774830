import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Youtube from "./mResponsiveYoutube";
export const _frontmatter = {
  "status": "published",
  "path": "/responsive-youtube/",
  "heroImage": "youtube.jpg",
  "title": "Responsive YouTube",
  "description": "Generate a Respsonsive YouTube Embed Video",
  "date": "2021-07-06T00:00:00.000Z",
  "tags": ["code", "video"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Every YouTube video provides a code that you can use to embed the video into a web page, but it is for a fixed iframe size. This means that the video is not mobile responsive. This results in a poor viewing experience for any person viewing the page on a device that has a narrow viewport width, like a phone. There is a good chance you may be viewing this post on your phone right now.`}</p>
    <p>{`I build a lot of landing pages at Amazon `}{`—`}{` I mean a lot. There is a good number of landing pages that require a YouTube video to be embedded on the page. I originally built this simple web app that wraps a responive video container around a YouTube video and exports the HTML code that you can paste into your page. I am hoping you may find this useful if you're encountering this issue.`}</p>
    <Youtube mdxType="Youtube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      